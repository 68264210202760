import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { Container } from "../components"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { post } }) => {
  const image = getImage(post.featuredImage.node.localFile)
  const category = post.categories.nodes[0].name
  return (
    <Container>
      <Seo title={post.title} description={post.content} />
      <div className="bg-white py-8 px-4">
        <div className="md:px-6 xl:px-0 max-w-5xl mx-auto tracking-wider mb-12 md:mb-24">
          <div className="mb-8">
            {/* 日付 */}
            <time
              className="text-primary-gold font-bold text-sm"
              dateTime={post.date}
            >
              {post.date}
            </time>
            {/* カテゴリー */}
            <div className="flex items-center gap-x-2 mt-2">
              <svg
                className="w-3 h-3"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30 30H17.1429V17.1429H30V30ZM12.8571 30H0V17.1429H12.8571V30ZM30 12.8571H17.1429V0H30V12.8571ZM12.8571 12.8571H0V0H12.8571V12.8571Z"
                  fill="#BF953F"
                />
              </svg>
              <h2 className="text-bold text-sm">{category}</h2>
            </div>
          </div>
          {/* タイトル */}
          <div className="w-fit-content mx-auto mb-8">
            <h1 className="font-semibold text-lg md:text-xl">{post.title}</h1>
            <div className="mt-2 w-full h-0.5 bg-primary-gold rounded-full" />
          </div>
          {/* サムネ画像 */}
          <div className="mb-12 md:mb-16 md:w-1/2 md:mx-auto">
            <GatsbyImage
              className="w-full"
              image={image}
              alt={`${post.title} 画像`}
            />
          </div>
          {/* 本文 */}
          <div
            className="text-base md:text-lg"
            dangerouslySetInnerHTML={{
              __html: `${post.content}`,
            }}
          />
        </div>
      </div>
    </Container>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "Y/MM/DD")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      categories {
        nodes {
          name
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
